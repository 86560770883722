import { register, init, locale, getLocaleFromNavigator, _ } from "svelte-i18n";
import {it} from 'date-fns/locale'

const mylang = getLocaleFromNavigator()
const locales = { it }

register('en', () => import('/static/lang/en.json'));
register('it', () => import('/static/lang/it.json'));

init({
    fallbackLocale: 'en',
    initialLocale: mylang,
});

function i18n() {
    locale.set(mylang);
}

export {
    i18n,
    getLocaleFromNavigator,
    locales, mylang,
    _,
}