<script>
  import logo from "/src/assets/img/logo.svg";
  import About from "/src/components/About";
  import Settings from "/src/components/Settings";
</script>

<header class="header">
  <div class="header__side">
    <About />
  </div>
  <h1 class="header__title">Radio 🎶 Orwell 🎵 Fun</h1>
  <div class="header__side txtright">
    <Settings />
  </div>
</header>
