<script>
  import { getContext } from "svelte";
  import { writable, derived } from "svelte/store";
  import Portal from "svelte-portal";
  import ContextPage from "/src/components/ContextPage";
  import TagInput from "/src/components/TagInput";
  import { isLoading, _ } from "svelte-i18n";

  const domain = getContext("domain");
  const hashtags = getContext("hashtags");

  const servers = [
    { dom: "orwell.fun", name: "Orwell Fun Community" },
    { dom: "livellosegreto.it", name: "Livello Segreto" },
    { dom: "mastodon.social", name: "Mastodon Ufficiale" },
    { dom: "mastodon.uno", name: "Mastodon Uno" },
    { dom: "mastodon.art", name: "Mastodon Art" },
    { dom: "spinster.xyz", name: "Spinster" },
    { dom: "mamot.fr", name: "Mamot FR" },
    { dom: "mstdn.jp", name: "mstdn JP" },
    { dom: "twingyeo.kr", name: "Twingyeo KR" },
  ];

  const domainValue = writable($domain);
  const domainState = derived(
    [domainValue],
    async ([$domainValue], set) => {
      if ($domainValue === "") {
        set({
          valid: false,
          error: $_("settings.error1"),
        });
      } else {
        try {
          const response = await fetch(
            `https://${$domainValue}/api/v1/instance`
          );
          const instance = await response.json();

          if (response.ok) {
            set({ valid: true, error: null });
          } else {
            set({ valid: false, error: $_("settings.error2") });
          }
        } catch (error) {
          set({
            valid: false,
            error: $_("settings.error3"),
          });
        }
      }
    },
    { valid: false, error: "" }
  );

  const hashtagsValue = writable($hashtags);
  const hashtagsState = derived([hashtagsValue], ([$hashtagsValue]) => {
    if ($hashtagsValue.some((hashtag) => !/^[a-z0-9]+$/i.test(hashtag))) {
      return {
        valid: false,
        error: $_("settings.error4"),
      };
    } else if ($hashtagsValue.length === 0) {
      return { valid: false, error: $_("settings.error5") };
    } else {
      return { valid: true, error: null };
    }
  });

  const valid = derived([domainState, hashtagsState], (states) =>
    states.every((state) => state.valid)
  );

  const handleSubmit = (event) => {
    event.preventDefault();

    if ($valid) {
      $domain = $domainValue;
      $hashtags = $hashtagsValue;
      location.reload(); // for now
    }
  };

  let open = false;

  let openPage = () => {
    open = true;
  };

  let closePage = () => {
    open = false;
  };
</script>

{#if !$isLoading}
  <button class="header__sideBtn" on:click={() => openPage()}
    >{$_("header.settings")}</button
  >
{/if}
<Portal target={document.body}>
  <ContextPage name={$_("header.settings")} {open} on:close={closePage}>
    <form class="settings" on:submit={handleSubmit}>
      <div class="settings-line">
        <div class="settings-side">
          <div class="settings-side__title">{$_("settings.instance")}</div>
          <div class="settings-side__subtitle">
            {$_("settings.instance_d")}
          </div>
        </div>
        <div class="settings-main">
          {#each servers as srv}
            <button
              type="button"
              class="btn btn--secondary btn--small settings-srv"
              on:click={() => ($domainValue = srv.dom)}
            >
              {srv.name}
            </button>
          {/each}
          <input
            class="f-size-full"
            type="text"
            id="domain"
            name="domain"
            on:change={(event) => ($domainValue = event.target.value)}
            value={$domainValue}
          />
          {#if !$domainState.valid}
            <div class="notif notif--error">{$domainState.error}</div>
          {/if}
        </div>
      </div>
      <div class="settings-line">
        <div class="settings-side">
          <div class="settings-side__title">{$_("settings.tags")}</div>
          <div class="settings-side__subtitle">{$_("settings.tags_d")}</div>
        </div>
        <div class="settings-main">
          <TagInput
            on:change={(event) => ($hashtagsValue = event.detail)}
            value={$hashtagsValue}
          />
          {#if !$hashtagsState.valid}
            <div class="notif notif--error">{$hashtagsState.error}</div>
          {/if}
          {#if $hashtagsValue.length > 5}
            <div class="notif notif--warning">
              {$_("settings.tags_w")}
            </div>
          {/if}
        </div>
      </div>
      <!--
      <div class="settings-line">
        <div class="settings-side">
          <div class="settings-side__title">Max duration</div>
          <div class="settings-side__subtitle">
            Hide tracks above this duration
          </div>
        </div>
        <div class="settings-main">
          <div class="radiobox radiobox--full">
            <input type="radio" id="5min" name="drone" value="5" />
            <label for="5min">5 min</label>
            <input type="radio" id="10min" name="drone" value="10" />
            <label for="10min">10 min</label>
            <input type="radio" id="20min" name="drone" value="20" checked />
            <label for="20min">20 min</label>
            <input type="radio" id="1hour" name="drone" value="60" />
            <label for="1hour">1 hour</label>
            <input type="radio" id="nolimit" name="drone" value="null" />
            <label for="nolimit">No limit</label>
          </div>
        </div>
      </div>
    -->
      <button class="btn btn--primary w100" type="submit" disabled={!$valid}>
        {$_("settings.save")}
      </button>
    </form>
  </ContextPage>
</Portal>
