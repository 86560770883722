<script>
  import Portal from "svelte-portal";
  import ContextPage from "/src/components/ContextPage";
  import { _ } from "/src/services/i18n";

  let open = false;

  let openPage = () => {
    open = true;
  };

  let closePage = () => {
    open = false;
  };
</script>

<button class="header__sideBtn" on:click={() => openPage()}
  >{$_("header.info")}</button
>
<Portal target={document.body}>
  <ContextPage name={$_("header.info")} {open} on:close={closePage}>
    <p class="txtbig">
      {$_("about.intro")}
    </p>
    <hr />
    <h2>{$_("about.Q1")}</h2>
    <p>{$_("about.A1")}</p>
    <h2>{$_("about.Q2")}</h2>
    <p>{$_("about.A2")}</p>
    <h2>{$_("about.Q3")}</h2>
    <p>{$_("about.A3")}</p>
    <h2>{$_("about.Q4")}</h2>
    <p>{$_("about.A4")}</p>
  </ContextPage>
</Portal>
