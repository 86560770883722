<script>
  import { getContext } from "svelte";
  import DistanceDate from "/src/components/DistanceDate";
  import IconMenu from "/src/components/icons/Menu";
  import Popper from "/src/components/PopperMenu";
  import ContextMenu from "/src/components/ContextMenu";
  import { _ } from "/src/services/i18n";

  const current = getContext("current");
  const enqueueing = getContext("enqueueing");
  const next = getContext("next");
  const queue = getContext("queue");
  const select = getContext("select");
  const paused = getContext("paused");

  $: history = $queue.filter((x) => x !== $next).reverse();
</script>

<div>
  <div class="queue__section">
    <div class="queue__sectionTitle">{$_("player.next")}</div>
    {#if $next || $enqueueing}
      <div class="track">
        <!-- svelte-ignore a11y-click-events-have-key-events -->
        <div class="track__main" on:click={() => select($next)}>
          <div class="track__title" class:placeholder={!$next && $enqueueing}>
            {#if $next}{$next.media.title}{/if}
          </div>
          <div
            class="track__subtitle"
            class:placeholder={!$next && $enqueueing}
          >
            {#if $next}
              {$_("player.sharedby")}
              <span class="track__username">{$next.referer.username}</span>
              •
              <DistanceDate date={$next.referer.date} />
            {/if}
          </div>
        </div>
        {#if $next}
          <Popper needOffset={true}>
            <button slot="btn" class="track__menu" aria-label="track menu"
              ><IconMenu /></button
            >
            <div slot="content" class="contextMenu__list">
              <ContextMenu track={$next} />
            </div>
          </Popper>
        {/if}
      </div>
    {:else}
      <div class="notif notif--warning">
        {$_("player.nomore")}
      </div>
    {/if}
  </div>

  <div class="queue__section">
    <div class="queue__sectionTitle">{$_("player.history")}</div>
    {#each history as track, i (track.referer.url)}
      <div
        class="track track--history"
        class:track--active={track === $current}
        class:track--playing={!$paused}
      >
        <!-- svelte-ignore a11y-click-events-have-key-events -->
        <div class="track__main" on:click={() => select(track)}>
          <div class="track__title">{track.media.title}</div>
          <div class="track__subtitle">
            {$_("player.sharedby")}
            <span class="track__username">{track.referer.username}</span>
            •
            <DistanceDate date={track.referer.date} />
          </div>
        </div>
        <Popper needOffset={true}>
          <button slot="btn" class="track__menu" aria-label="track menu"
            ><IconMenu /></button
          >
          <div slot="content" class="contextMenu__list">
            <ContextMenu {track} />
          </div>
        </Popper>
      </div>
    {/each}
    {#if history.length === 0}
      <div class="track">
        <div class="track__main">
          <div class="track__title placeholder" />
          <div class="track__subtitle placeholder" />
        </div>
      </div>
    {/if}
  </div>
</div>
