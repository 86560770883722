var _require = require('../../internal/iterable.js'),
    iterableCurry = _require.iterableCurry;

var _require2 = require('../$peekerate/peekerate.js'),
    __peekerate = _require2.__peekerate;

function __findBestOr(iterable, notFoundValue, comparer) {
  var mapper = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : function (value) {
    return value;
  };

  var peekr = __peekerate(iterable);

  if (peekr.done) {
    return notFoundValue;
  } else {
    var best = peekr.value;
    var bestValue = mapper(best, peekr.index);
    peekr.advance();

    while (!peekr.done) {
      var candidate = peekr.value;
      var candidateValue = mapper(candidate, peekr.index);

      if (comparer(bestValue, candidateValue)) {
        best = candidate;
        bestValue = candidateValue;
      }

      peekr.advance();
    }

    return best;
  }
}

exports.__findBestOr = __findBestOr;
var findBestOr = iterableCurry(__findBestOr, {
  reduces: true,
  minArgs: 2,
  maxArgs: 3,
  growRight: true
});
exports.findBestOr = findBestOr;