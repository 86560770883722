function __call(fn) {
  for (var _len = arguments.length, args = new Array(_len > 1 ? _len - 1 : 0), _key = 1; _key < _len; _key++) {
    args[_key - 1] = arguments[_key];
  }

  return fn.apply(void 0, args);
}

exports.__call = __call;
var call = __call;
exports.call = call;