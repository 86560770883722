var _require = require('../../internal/iterable.js'),
    iterableCurry = _require.iterableCurry;

var _require2 = require('../$find-best-or/find-best-or.js'),
    __findBestOr = _require2.__findBestOr;

function __findBest(iterable, comparer) {
  var mapper = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : function (value) {
    return value;
  };
  return __findBestOr(iterable, undefined, comparer, mapper);
}

exports.__findBest = __findBest;
var findBest = iterableCurry(__findBest, {
  reduces: true,
  minArgs: 1,
  maxArgs: 2,
  growRight: true
});
exports.findBest = findBest;